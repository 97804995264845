import { PostType } from '@/types/wordpress'

import ArticlePreview from '../ArticlePreview/ArticlePreview'

import ArticlePagination from './ArticlePagination'

type Props = {
  pageCount: number
  pageNum: number
  path: string
  posts: PostType[]
}

const ArticleTypePage = ({ pageCount, pageNum, path, posts }: Props) => (
  <>
    <div className='d-flex flex-column gap-3'>
      {posts.map((post) => (
        <ArticlePreview key={post.slug} {...post} />
      ))}
    </div>
    <ArticlePagination pageCount={pageCount} pageNum={pageNum} path={path} />
  </>
)

export default ArticleTypePage
