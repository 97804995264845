import classNames from 'classnames'
import Link from 'next/link'
import BSCard from 'react-bootstrap/Card'

import { PostType } from '@/types/wordpress'

import styles from './ArticlePreview.module.scss'
import Author from './Author'
import Date from './Date'
import FeaturedImage from './FeaturedImage'
import Tags from './Tags'

const ArticlePreview = ({ author, date, excerpt, featuredImageUrl, slug, tags, title }: PostType) => (
  <BSCard className={classNames(styles.container, 'shadow-sm')}>
    {featuredImageUrl ? (
      <FeaturedImage cover height='auto' slug={slug} src={featuredImageUrl} title={title} width={300} />
    ) : (
      <div className={styles.featuredImagePlaceholder} />
    )}
    <div className='d-flex flex-column gap-2 justify-content-between m-3'>
      <div className='d-flex flex-column gap-2'>
        <h3>
          <Link
            className={styles.hover}
            dangerouslySetInnerHTML={{ __html: title }}
            href={`/articles/${slug}`}
            prefetch={false}
          />
        </h3>
        <div className='align-items-center d-flex gap-2'>
          <Date dateString={date} />
          <div className='text-secondary'>by</div>
          <Author author={author} />
        </div>
        <div>{excerpt}</div>
      </div>
      <Tags tags={tags} />
    </div>
  </BSCard>
)

export default ArticlePreview
