import Link from 'next/link'

import { AuthorType } from '@/types/wordpress'

import styles from './Author.module.scss'

type Props = { author: AuthorType }

const Author = ({ author: { avatarUrl, name, slug } }: Props) => (
  <Link className='d-flex gap-2 align-items-center ' href={`/articles/author/${slug}`} prefetch={false}>
    <div dangerouslySetInnerHTML={{ __html: name }} />
    <img alt={name} className={styles.avatar} src={avatarUrl} />
  </Link>
)

export default Author
